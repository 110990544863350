import TopHeader from "components/common/header";
import SkeletonBlock, { SkeletonBlock2 } from ".";
import { useRouter } from "next/router";
import DividerLine from "components/common/divider/line";

export const HighTotalScoreSkeleton = () => {
  return (
    <div className="flex flex-col w-full gap-2 px-6">
      <div className="pt-6">
        <SkeletonBlock width={170} height={24} />
      </div>
      <div className="py-2 flex flex-col gap-3">
        <SkeletonBlock width={250} height={50} />
        <SkeletonBlock width={250} height={50} />
        <SkeletonBlock width={250} height={50} />
      </div>
    </div>
  );
};

export const MyStockSkeleton = () => {
  return (
    <div>
      <div className="p-5 w-full">
        <SkeletonBlock width={"100%"} height={100} />
      </div>
      <div className=" ">
        <HighTotalScoreSkeleton />
      </div>
    </div>
  );
};

export const TradeSkeleton = () => {
  const router = useRouter();
  return (
    <div className="flex flex-col">
      <div className="relative h-[56px]">
        <div className="absolute top-0 left-0 w-full">
          <TopHeader
            clickHandler={() => {
              router.back();
            }}
          />
        </div>
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <SkeletonBlock width={90} height={28} />
        </div>
      </div>
      <div className="px-6 py-4 flex flex-col gap-2">
        <div className="flex justify-end items-center">
          <SkeletonBlock width={70} height={22} />
        </div>
        <SkeletonBlock width={"100%"} height={102} />
        <SkeletonBlock width={"100%"} height={126} />
      </div>
    </div>
  );
};
export const AccountDetailSkeleton = () => {
  const router = useRouter();
  return (
    <div className="flex flex-col w-full">
      <div className="relative h-[56px]">
        <div className="absolute top-0 left-0 w-full">
          <TopHeader
            clickHandler={() => {
              router.back();
            }}
          />
        </div>
      </div>
      <div className="pt-6 px-6 flex flex-col gap-1">
        <SkeletonBlock width={160} height={92} />
      </div>
      <div className="flex px-6 py-2 gap-2 items-center">
        <div className="grow">
          <SkeletonBlock width={"100%"} height={48} />
        </div>
        <div className="grow">
          <SkeletonBlock width={"100%"} height={48} />
        </div>
      </div>
      <div className="py-4 px-6 ">
        <SkeletonBlock width={117} height={54} />
      </div>
      <div className="py-2 flex flex-col gap-2">
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
      </div>
      <div className="py-4 px-6 ">
        <SkeletonBlock width={117} height={54} />
      </div>
      <div className="py-2 flex flex-col gap-2">
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
      </div>
    </div>
  );
};

export const AccountTradeSkeleton = () => {
  const router = useRouter();
  return (
    <div className="flex flex-col w-full">
      <div className="relative h-[56px]">
        <div className="absolute top-0 left-0 w-full">
          <TopHeader
            clickHandler={() => {
              router.back();
            }}
          />
        </div>
      </div>
      <div className="py-4 px-6 ">
        <SkeletonBlock width={117} height={54} />
      </div>
      <div className="py-2 flex flex-col gap-2">
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
      </div>
      <div className="flex px-6 py-2 gap-2 items-center">
        <div className="grow">
          <SkeletonBlock width={"100%"} height={48} />
        </div>
        <div className="grow">
          <SkeletonBlock width={"100%"} height={48} />
        </div>
      </div>
      <DividerLine size="lg" />
      <div className="py-2 flex flex-col gap-1 pt-3">
        <div className="px-6 py-1 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-1 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-1 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
      </div>
    </div>
  );
};

export const OrderListSkeleton = () => {
  const router = useRouter();

  return (
    <div className="flex flex-col w-full">
      <div className="relative h-[56px]">
        <div className="absolute top-0 left-0 w-full">
          <TopHeader
            clickHandler={() => {
              router.back();
            }}
          />
        </div>
      </div>
      <div className="pt-6 px-6 flex flex-col gap-1">
        <SkeletonBlock width={160} height={40} />
      </div>
      <div className="py-2 flex flex-col gap-2">
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
        <div className="px-6 py-2 flex gap-3 items-center cursor-pointer">
          <SkeletonBlock width={"100%"} height={46} />
        </div>
      </div>
    </div>
  );
};
export const OrderDetailSkeleton = () => {
  const router = useRouter();

  return (
    <div className="flex flex-col w-full">
      <div className="relative h-[56px]">
        <div className="absolute top-0 left-0 w-full">
          <TopHeader
            clickHandler={() => {
              router.back();
            }}
          />
        </div>
      </div>
      <div className="pt-6 px-6 flex flex-col gap-1">
        <SkeletonBlock width={160} height={40} />
      </div>
      <div className="px-6 py-4">
        <SkeletonBlock width={"100%"} height={90} />
      </div>
      <div className="py-2 flex flex-col ">
        <div className="py-2 px-6">
          <SkeletonBlock width={"100%"} height={32} />
        </div>
        <div className="py-2 px-6">
          <SkeletonBlock width={"100%"} height={32} />
        </div>
      </div>
      <DividerLine size="lg" />
      <div className="py-2 flex flex-col gap-2">
        <div className="py-2 px-6">
          <SkeletonBlock width={"100%"} height={45} />
        </div>
      </div>
    </div>
  );
};
